import { ReactElement } from 'Type/Common.type';

export interface IconsContainerProps {
    name: IconName;
    fill?: string;
    width?: string;
    height?: string;
}

export enum IconName {
    ANGLE_RIGHT = 'angle_right',
    ARROW_DOWN = 'arrow_down',
    ARROW_LEFT = 'arrow_left',
    ARROW_RIGHT = 'arrow_right',
    ARROW_UP = 'arrow_up',
    BADGE = 'badge',
    BAG = 'bag',
    BOX = 'box',
    BURGER_MENU = 'burger_menu',
    CALENDAR = 'calendar',
    CALENDAR_DAY = 'calendar_day',
    CAR = 'car',
    CHAT = 'chat',
    CHECK = 'check',
    CHECK_CIRLCE = 'check_circle',
    CLIP = 'clip',
    CLOCK = 'clock',
    CLOCK_2 = 'clock_2',
    CLOSE = 'close',
    COMPARE = 'compare',
    DOWNLOAD_FILE = 'download_file',
    DOWNLOAD_PDF = 'download_pdf',
    DROPDOWN_ARROW = 'dropdown_arrow',
    DROPDOWN_ARROW_UP = 'dropdown_arrow_up',
    EDIT = 'edit',
    ENVELOP = 'envelop',
    ENVELOPE = 'envelope',
    EYE = 'eye',
    EYE_CROSSED = 'eye_crossed',
    FACEBOOK = 'facebook',
    FLATRATE = 'flatrate',
    FREESHIPPING = 'freeshipping',
    HAND = 'hand',
    HEART = 'heart',
    INFO_CIRCLE = 'info-circle',
    INSTAGRAM = 'instagram',
    LOGO_SMALL = 'logo_small',
    MAGNIFIER = 'magnifier',
    NEXT = 'next',
    PENCIL = 'pencil',
    PERSON_RUNNING = 'person_running',
    PHONE = 'phone',
    PHONE_2 = 'phone_2',
    PIN = 'pin',
    PINTEREST = 'pinterest',
    PLUS = 'plus',
    REORDER = 'reorder',
    TIME = 'time',
    TRASH = 'trash',
    TRUCK = 'truck',
    TRUCK_2 = 'truck_2',
    TWITTER = 'twitter',
    USER = 'user',
    WARNING = 'warning',
    WARNING_FILL = 'warning_fill',
    YOUTUBE = 'youtube',
    CHEVRON_RIGHT = 'chevron_right',
    ALERT = 'alert',
}

export interface IconsComponentProps {
    icon: ReactElement;
}
