Mosaic.addPlugins([require('/home/elektra_lt/projects/elektra/production/releases/53/app/design/frontend/PlugAndSell2/pwa/packages/gtm-new/src/plugin/ProductClick.plugin.js')]);
import { createRef, Suspense } from 'react';

import DiscountLabel from 'Component/DiscountLabel';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Label from 'Component/Label';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductType } from 'Component/Product/Product.config';
import ProductReviewRating from 'Component/ProductReviewRating';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { GroupedProductItem } from 'Query/ProductList.type';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { Children, ReactElement } from 'Type/Common.type';
import { roundPercentage } from 'Util/Percentage/Percentage';
import { IndexedConfigurableOption } from 'Util/Product/Product.type';

import productPlaceholder from '../../assets/images/productPlaceholder.png';
import { scrollToTop } from '../../util/Browser/Browser';
import { ProductComponent, ProductConfigurableAttributes } from '../Product/Product.component';
import { ContentObject, ProductCardComponentProps, ProductCardComponentState } from './ProductCard.type';

import './ProductCard.style';

/**
 * Product card
 * @class ProductCard
 * @namespace PlugAndSell2/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent<ProductCardComponentProps, ProductCardComponentState> {
    static defaultProps: Partial<ProductCardComponentProps> = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: '',
        children: null,
        isLoading: false,
        mix: {},
        renderContent: null,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: CategoryPageLayout.GRID,
    };

    state: ProductCardComponentState = {
        relatedImage: null,
    };

    contentObject: ContentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this),
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this),
        },
    };

    imageRef = createRef<HTMLImageElement>();

    className = 'ProductCard';

    __construct(props: ProductCardComponentProps): void {
        super.__construct?.(props);

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    renderLabels(): ReactElement {
        const {
            product: { labels },
        } = this.props;

        return (
            labels &&
            labels.map((label, index: number) => (
                <Label
                    key={`label${index}`}
                    image={label.image}
                    text={label.text}
                    text_background_color={label.text_background_color}
                    text_color={label.text_color}
                    url={label.url}
                />
            ))
        );
    }

    renderDiscountLabel(): ReactElement {
        const {
            product: { price_range: { minimum_price: { discount: { percent_off = 0 } = {} } = {} } = {} },
        } = this.props;

        if (!percent_off) {
            return;
        }

        return <DiscountLabel value={roundPercentage(percent_off)} mix={{ block: 'ProductCard', elem: 'DiscountLabel' }} />;
    }

    handleLinkClick(): void {
        const { registerSharedElement, isPlp } = this.props;

        if (!isPlp) {
            scrollToTop();
        }

        registerSharedElement(this.imageRef);
    }

    // #region PRICE
    renderEmptyProductPrice(): ReactElement {
        return <div block="ProductCard" elem="PriceWrapper" mods={{ isEmpty: true }} />;
    }

    renderPrice(): ReactElement {
        const { getActiveProduct, product: { type_id: baseType } = {}, inStock } = this.props;

        const { price_range: priceRange, type_id: typeId } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE && !inStock) {
            return null;
        }

        // If product is not a variant.
        const notConfigured = baseType !== ProductType.CONFIGURABLE || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
    // #endregion

    renderPicture(mix = {}): ReactElement {
        const {
            product: { id, name },
            thumbnail,
            onLoad,
        } = this.props;

        return thumbnail ? (
            <>
                <Image
                    imageRef={this.imageRef}
                    src={thumbnail}
                    alt={name}
                    ratio={ImageRatio.IMG_CUSTOM}
                    mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                    isPlaceholder={!id}
                    useNativeLazyLoading={false}
                    onImageLoad={onLoad}
                    lazyLoadingType="productTile"
                />
                <ImageLazyLoading style={{ display: 'none' }} alt={name} src={thumbnail} lazyLoadingType="productTile" />
            </>
        ) : (
            <img src={productPlaceholder} alt={name} />
        );
    }

    renderReviews(): ReactElement {
        const { layout } = this.props;

        return (
            <div block="ProductCard" elem="Reviews" mods={{ layout }}>
                {this.renderRatingSummary()}
            </div>
        );
    }

    renderRatingSummary(): ReactElement {
        const {
            product: { review_summary: { rating_summary = null, review_count = null } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count || 0} showCounter={false} />;
    }

    renderProductCompareButton(): ReactElement {
        const { hideCompareButton } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton(): ReactElement {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderProductActions(): ReactElement {
        return (
            <div block="ProductCard" elem="ProductActions">
                {this.renderProductCardWishlistButton()}
                {this.renderProductCompareButton()}
            </div>
        );
    }

    renderMainDetails(): ReactElement {
        const {
            product: { name },
        } = this.props;

        return (
            <p block="ProductCard" elem="Name" mods={{ isLoaded: !!name }}>
                <TextPlaceholder content={name} length={TextPlaceHolderLength.MEDIUM} />
            </p>
        );
    }

    renderCardLinkWrapper(children: Children, mix = {}): ReactElement {
        const {
            linkTo = '',
            product: { url },
        } = this.props;

        if (!url) {
            return (
                <div block="ProductCard" elem="Link">
                    {children}
                </div>
            );
        }

        return (
            <Link block="ProductCard" elem="Link" to={linkTo} onClick={this.handleLinkClick} mix={mix}>
                {children}
            </Link>
        );
    }

    requiresConfiguration(): boolean {
        const {
            parameters,
            product: { type_id: type, options = [], items = [], links_purchased_separately },
        } = this.props;

        const configureBundle = type === ProductType.BUNDLE;

        const allAttrs = super.getConfigurableAttributes();
        const plpConfigurableAttrs = this.getConfigurableAttributes();

        const isConfigurable = type === ProductType.CONFIGURABLE;

        const configureConfig =
            isConfigurable &&
            (Object.keys(allAttrs).length !== Object.keys(plpConfigurableAttrs).length ||
                Object.values(plpConfigurableAttrs).some((value) => value.attribute_values.length === 0) ||
                (Object.keys(allAttrs).length > 0 && Object.keys(parameters).length === 0));

        const configureGrouped = type === ProductType.GROUPED && (items as GroupedProductItem[]).every(({ qty }) => qty === 0);

        const configureCustomize = options.some(({ required = false }) => required);

        const configureDownloadableLinks = ProductType.DOWNLOADABLE && links_purchased_separately === 1;

        return configureGrouped || configureBundle || configureConfig || configureCustomize || configureDownloadableLinks;
    }

    renderAddToCart(): ReactElement {
        const { layout, showSelectOptionsNotification, inStock } = this.props;

        const requiresConfiguration = this.requiresConfiguration();

        if (inStock && requiresConfiguration) {
            return (
                <button block="Button AddToCart" mods={{ layout }} onClick={showSelectOptionsNotification}>
                    {__('Add to cart')}
                </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>{__('Out of stock')}</p>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    getConfigurableAttributes(): Record<string, IndexedConfigurableOption> {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(
            Object.entries(filteredOptions).filter(([, option]) => {
                const { attribute_options } = option;

                if (!attribute_options) {
                    return false;
                }

                return Object.values(attribute_options).some(({ swatch_data: swatchData }) => swatchData);
            })
        );
    }

    renderVisibleOnHover(): ReactElement {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return null;
    }

    renderDeliveryTime(): ReactElement {
        return (
            <div block="ProductCard" elem="DeliveryTime">
                {__('Delivery time')}:<span>{}</span>
            </div>
        );
    }

    renderAlternativePicture(): ReactElement {
        const {
            product: { id, name, alternative_hover_image },
        } = this.props;

        return alternative_hover_image ? (
            <Image
                src={alternative_hover_image}
                alt={name}
                ratio={ImageRatio.IMG_CUSTOM}
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        ) : null;
    }

    renderRelatedProduct(): ReactElement {
        const { relatedImage } = this.state;

        return relatedImage ? (
            <Image
                src={relatedImage}
                alt="dasdad"
                ratio={ImageRatio.IMG_CUSTOM}
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={false}
            />
        ) : null;
    }

    renderName(): ReactElement {
        const {
            product: { name },
        } = this.props;

        return (
            <TypographyHeader block="ProductCard" elem="Name" mods={{ isLoaded: !!name }} variant={Variant.NORMAL} tag="h3">
                {name}
            </TypographyHeader>
        );
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (type !== ProductType.CONFIGURABLE || !Object.keys(this.getConfigurableAttributes()).length || !inStock) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AttributesWrapper">
                <Suspense fallback={null}>
                    <ProductConfigurableAttributes
                        // eslint-disable-next-line no-magic-numbers
                        numberOfPlaceholders={[2, 4]}
                        updateAddToCartTriggeredWithError={updateAddToCartTriggeredWithError}
                        addToCartTriggeredWithError={addToCartTriggeredWithError}
                        mix={{ block: this.className, elem: 'Attributes' }}
                        parameters={parameters}
                        variants={variants}
                        updateConfigurableVariant={setActiveProduct}
                        configurable_options={this.getConfigurableAttributes()}
                        isContentExpanded
                        inStock={inStock}
                        showProductAttributeAsLink={false}
                    />
                </Suspense>
            </div>
        );
    }

    renderCardContent(): ReactElement {
        const {
            renderContent,
            product: { name },
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <div block="ProductCard" elem="LinkInnerWrapper" mods={{ loaded: !!name }}>
                {this.renderDiscountLabel()}
                <div block="ProductCard" elem="LabelsWithActions">
                    <div block="ProductCard" elem="Labels">
                        {this.renderLabels()}
                    </div>
                    {this.renderProductActions()}
                </div>
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                    </figure>
                    <figure block="ProductCard" elem="FigureAlternative">
                        {this.renderAlternativePicture()}
                    </figure>
                    <figure block="ProductCard" elem="FigureRelatedProduct">
                        {this.renderRelatedProduct()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    <div block="ProductCard" elem="Price">
                        {this.renderPrice()}
                    </div>
                    {this.renderName()}
                </div>
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </div>
        );
    }

    renderCardListContent(): ReactElement {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <>
                <div block="ProductCard" elem="FigureReview">
                    <div block="ProductCard" elem="Labels">
                        {this.renderDiscountLabel()}
                        {this.renderLabels()}
                    </div>
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                    </figure>
                    <figure block="ProductCard" elem="FigureAlternative">
                        {this.renderAlternativePicture()}
                    </figure>
                    <figure block="ProductCard" elem="FigureRelatedProduct">
                        {this.renderRelatedProduct()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={{ layout }}>
                    <div block="ProductCard" elem="MainInfo">
                        {this.renderMainDetails()}
                        {this.renderBrand()}
                        {this.renderPrice()}
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        {this.renderProductActions()}
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                </div>
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    render(): ReactElement {
        const { children, mix, isLoading, layout, sliderMode } = this.props;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li block="ProductCard" mods={{ layout }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={{ layout, isSlider: sliderMode }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
